<template>
  <div ref="page" class="page search-page">
    <div class="container">
      <section class="section-title">
        <el-row type="flex" justify="end">
          <el-button data-cy="club-card-generate" @click="generateClubCard" type="success">Générer une Club Card</el-button>
        </el-row>
      </section>
      <section class="section-container form-container" v-loading="isLoading">
        <h1>Club card générées</h1>

        <template v-if="list.length > 0">
          <el-table :data="list" empty-text="Pas de clubistes">
            <el-table-column prop="clubCardId" label="clubCardId">
              <template slot-scope="scope">
                <span data-cy="club-card-id">
                  {{ scope.row.clubCardId }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </template>

      </section>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      list: [],
      isLoading: false
    }
  },

  methods: {
    generateClubCard () {
      this.isLoading = true
      this.$store.dispatch('admin/generateClubCard')
        .then(clubCard => {
          console.log(clubCard)
          console.log(this.list)
          this.list.push(clubCard)
          return clubCard
        })
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
